// prefer default export if available
const preferDefault = m => m && m.default || m

exports.components = {
  "component---src-pages-404-tsx": () => import("./../src/pages/404.tsx" /* webpackChunkName: "component---src-pages-404-tsx" */),
  "component---src-pages-account-tsx": () => import("./../src/pages/account.tsx" /* webpackChunkName: "component---src-pages-account-tsx" */),
  "component---src-pages-customers-customer-detail-tsx": () => import("./../src/pages/customers/customer-detail.tsx" /* webpackChunkName: "component---src-pages-customers-customer-detail-tsx" */),
  "component---src-pages-index-tsx": () => import("./../src/pages/index.tsx" /* webpackChunkName: "component---src-pages-index-tsx" */),
  "component---src-pages-login-tsx": () => import("./../src/pages/login.tsx" /* webpackChunkName: "component---src-pages-login-tsx" */),
  "component---src-pages-payments-paystub-detail-tsx": () => import("./../src/pages/payments/paystub-detail.tsx" /* webpackChunkName: "component---src-pages-payments-paystub-detail-tsx" */),
  "component---src-pages-payments-tsx": () => import("./../src/pages/payments.tsx" /* webpackChunkName: "component---src-pages-payments-tsx" */),
  "component---src-pages-uploads-history-tsx": () => import("./../src/pages/uploads/history.tsx" /* webpackChunkName: "component---src-pages-uploads-history-tsx" */),
  "component---src-pages-uploads-tsx": () => import("./../src/pages/uploads.tsx" /* webpackChunkName: "component---src-pages-uploads-tsx" */),
  "component---src-pages-users-tsx": () => import("./../src/pages/users.tsx" /* webpackChunkName: "component---src-pages-users-tsx" */),
  "component---src-pages-users-user-detail-tsx": () => import("./../src/pages/users/user-detail.tsx" /* webpackChunkName: "component---src-pages-users-user-detail-tsx" */)
}

